export default function Contact() {
    return (
        <div id="contact" className="center slim">
            <h2>
                Contact Me
            </h2>
            <p>
                For any questions or work inquiries, you may email me at:
            </p>
            <a
                href="mailto:saltmalkin@gmail.com"
                className="email"
                target="_blank"
                rel="noopener noreferrer"
            >
                saltmalkin@gmail.com
            </a>
            <br />
            <h2>
                About Me
            </h2>
            <p className="center">
                Hello! My name is Alaina Sang.
                <br />
                I am a queer Asian illustrator living in Berkeley, CA with my partner and my two cats. I have a primary interest in fantasy or animal-related themes. I also have an obsession with werewolves.
            </p>
            <p className="center">
                I graduated with a BFA in Painting with a focus on oils, and a minor in Biology, from the University of Denver with the intent of pursuing scientific illustration. Over time, my work led me to the video game industry instead.
            </p>
            <p className="center">
                These days, I am primarily focused on my freelance endeavors. Most of my work is geared towards personal projects like making prints, shirts, and merchandise. I am still very much open for commercial work and would love to work with you!
            </p>
            <br />
            <h2>Socials</h2>
            <ul>
                <li>
                    <span>TWITTER: </span>
                    <a href="https://twitter.com/saltmalkin">https://twitter.com/saltmalkin</a>
                </li>
                <li>
                    <span>TUMBLR: </span>
                    <a href="https://saltmalkin.tumblr.com/">https://saltmalkin.tumblr.com/</a>
                </li>
                <li>
                    <span>INSTAGRAM: </span>
                    <a href="https://www.instagram.com/saltmalkin/">https://www.instagram.com/saltmalkin/</a>
                </li>
            </ul>
        </div>
    )
}
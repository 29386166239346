import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import Page from './components/Page';
import './index.css';
import Contact from './routes/Contact';
import SingleImage from './routes/SingleImage';
import { portfolioImages } from "./img/portfolio/all";
import { childrensImages } from "./img/childrens/all";
import { conventionImages } from "./img/convention/all";
import Portfolio from './routes/Portfolio';
import Childrens from './routes/Childrens';
import Convention from './routes/Convention';

export const navMap = [
  {
    path: '/',
    element: 
      <Page
        selected='Portfolio'
        content={
          <Portfolio />
        }
      />,
    name: 'Portfolio',
  },
  {
    path: '/childrens',
    element:
      <Page
        selected='Childrens'
        content={
          <Childrens />
        }
      />,
    name: 'Convention',
  },
  {
    path: '/convention',
    element:
      <Page
        selected='Convention'
        content={
          <Convention />
        }
      />,
    name: 'Convention',
  },
  {
    path: '/contact',
    element:
      <Page
        selected='Contact Info'
        content={<Contact />}
      />,
    name: 'Contact Info',
  },
  {
    path: 'portfolio/image/:id',
    element: <Page
      content={
        <SingleImage
          images={portfolioImages}
          folder='portfolio'
        />
      }
    />,
    name: 'Image',
  },
  {
    path: 'childrens/image/:id',
    element: <Page
      content={
        <SingleImage
          images={childrensImages}
          folder='childrens'
          galleryName={'Children\'s'}
        />
      }
    />,
    name: 'Image',
  },
  {
    path: 'convention/image/:id',
    element:
      <Page
        content={
          <SingleImage
          images={conventionImages}
          folder='convention'
        />
      }
    />,
    name: 'Image',
  }
]
const router = createHashRouter(navMap);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

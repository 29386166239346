import Gallery from "./Gallery";
import { conventionImages } from "../img/convention/all";

export default function Portfolio() {
  return (
    <Gallery
      images={conventionImages}
      folder="convention"
    />
  )
}
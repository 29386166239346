import { Link } from "react-router-dom";
import { ReactComponent as Chevron } from "../img/misc/down-chevron.svg"
import { useState } from "react";

interface NavDropdownProps {
  selected?: string;
}

const GALLERIES = [
  {
    name: 'Portfolio',
    link: '/',
    display: 'Portfolio',
  },
  {
    name: 'Childrens',
    link: '/childrens',
    display: 'Children\'s',
  },
  {
    name: 'Convention',
    link: '/convention',
    display: 'Conventions',
  },
]

export default function NavDropdown(props: NavDropdownProps) {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <div
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
      onClick={() => setShowDropdown(true)}
    >
      <div
        className="nav-item"
      >
        Gallery
        <Chevron className="dropdown-chevron"/>
      </div>
      <div className={`dropdown-content ${showDropdown && 'show'}`}>
        {GALLERIES.map(gallery => {
          return (
            <Link to={gallery.link}>
              <div className={props.selected === gallery.name ? 'nav-item selected': 'nav-item'}>
                {gallery.display}
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
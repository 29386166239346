import Gallery from "./Gallery";
import { childrensImages } from "../img/childrens/all";

export default function Childrens() {
  return (
    <Gallery
      images={childrensImages}
      folder="childrens"
    />
  )
}
import Gallery from "./Gallery";
import { portfolioImages } from "../img/portfolio/all";

export default function Portfolio() {
  return (
    <Gallery
      images={portfolioImages}
      folder="portfolio"
    />
  )
}